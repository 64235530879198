<template>
  <vs-tabs class="clinic-users-tab">
    <vs-tab label="Basic Users">
      <basic-users-table
        :canDelete="true"
        :canCreate="true"
        createRoute="SuperAdminCreateBasicUser"
        :inTabEdit="true"
      />
    </vs-tab>
    <vs-tab label="Nurses">
      <nurse-table
        :canDelete="true"
        viewRoute="SuperAdminNurseDetails"
        :canCreate="true"
        viewDetailRoute="SuperAdminNurseDetailView"
        createRoute="SuperAdminNurseCreate"
        :inTabEdit="true"
      />
    </vs-tab>
    <vs-tab label="Doctors">
      <doctor-table
        :canDelete="true"
        viewRoute="SuperAdminDoctorEdit"
        viewDetailRoute="SuperAdminDoctorDetail"
        :canCreate="true"
        createRoute="SuperAdminDoctorCreate"
        :inTabEdit="true"
      />
    </vs-tab>
    <vs-tab label="EMR Doctors">
      <EMRDoctorTable
        :canDelete="true"
        viewRoute="SuperAdminDoctorEdit"
        viewDetailRoute="SuperAdminDoctorDetail"
        :canCreate="true"
        createRoute="SuperAdminDoctorCreate"
        :inTabEdit="true"
      />
    </vs-tab>
  </vs-tabs>
</template>

<script>
import doctorTable from "../doctors/doctorTable.vue";
import EMRDoctorTable from "../emr-doctor/emrDoctorTable.vue";
import nurseTable from "../nurses/nurseTable.vue";
import basicUsersTable from "../basic-user/basicUsersTable.vue";

export default {
  components: {
    doctorTable,
    nurseTable,
    EMRDoctorTable,
    basicUsersTable
  },
};
</script>

<style>
.clinic-users-tab .con-ul-tabs {
  height: 40px;
  overflow-x: auto;
  overflow-y: hidden;
}

.clinic-users-tab .con-ul-tabs::-webkit-scrollbar {
  display: none;
}
</style>
